import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material'
import { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';

const SidebarElement = ({ title, isGroup, subgroups, childElements, selectedCategory, setSelectedCategory }) => {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        if (subgroups?.length > 0)
            setExpanded(isExpanded ? panel : false);
        else
            setExpanded(false)
    };

    return (

        <Accordion
            onClick={() => subgroups === undefined && setSelectedCategory(title)}
            expanded={(expanded === 'panel1')}
            onChange={handleChange('panel1')}
            style={{
                background: (selectedCategory === title) ? '#FC1503' : 'black',
                width: '200px'
            }}
            color='white'

        >
            <AccordionSummary
                expandIcon={subgroups?.length > 0 && <ExpandMore style={{ color: 'white' }}/>}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography sx={{ width: '100%', flexShrink: 0 }}
                    style={{ color: 'white' }}
                >
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {subgroups?.map(subgroup => {
                    return (
                        <Button key={subgroup.name}
                            onClick={() => {
                                setSelectedCategory((subgroup.name))
                                setExpanded(false)
                            }}
                            style={{ 
                                background: (selectedCategory === subgroup.name && '#FC1503'), 
                                width: 'auto',
                                textAlign: 'left' }}>
                            {subgroup.viewName}
                        </Button>)
                })}

            </AccordionDetails>
        </Accordion>

    )
}

export default SidebarElement