import { Stack } from "@mui/material";
import { all } from "../utils/content";
import SidebarElement from "./SidebarElement";

const Sidebar = ({ selectedCategory, setSelectedCategory }) => {
  return (
    <Stack
      direction="row"
      sx={{
        overflowY: "auto",
        height: { sx: "auto", md: "95%" },
        flexDirection: { md: "column" },
      }}
    >
      {all.map((category) => (
        <SidebarElement
          title={category.name}
          subgroups={category.subgroups}
          isGroup={category.videos?.length <= 0}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          childElements={[]}
          key={category.name}
        />
      ))}
    </Stack>
  );
};

export default Sidebar;
