import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import ReactPlayer from 'react-player'
import {Stack, Box, Typography} from '@mui/material'
import {CheckCircle} from '@mui/icons-material'
import { fetchFromApi } from '../utils/fetchFromApi'



const VideoDetail = () => {
  const {id} = useParams()
  const [videoDetail, setVideoDetail] = useState(null)
  const [videos, setVideos] = useState([])

  
  useEffect(() => {
    if(window.location.href.includes('eglen')) {
      document.title = "İzle Eğlen"
    } else {
      document.title = "Gözden Kalbe"
    }
  }, [id])
  
  return (
    <Box marginTop='30px' minHeight='95vh'>
      <Stack direction={{xs:'column', md:'row'}}>
        <Box flex={1}>
          <Box sx={{width: '100%', position: 'sticky', top:'86px'}}>
            <ReactPlayer url={`https://www.youtube.com/watch?v=${id}`}
              className='react-player'
              controls
            />
            <Stack
              direction='row'
              justifyContent='space-between'
              sx= {{
                color:'white'
              }}
              px={2}
              py={1}
            >
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}

export default VideoDetail