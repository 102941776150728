import axios from "axios";


const BASE_URL = 'https://www.googleapis.com/youtube/v3'
const options = {
    params: {
      part: 'id',
      maxResults: '5',
      key: process.env.REACT_APP_YTAK
    }
  };

  const youtube = axios.create({
      baseURL: BASE_URL,
      params: {
        part: 'snippet',
        maxResults: '50',
        key: process.env.REACT_APP_YTAK
      }
   })
  export const fetchFromApi = async (url, params) => {
    const { data } = await youtube.get(url, {params: { ...youtube.params, ...params }})
    return data;
  }