const adultContents = [
    ...require("./tevhid.json"),
    ...require("./kuran.json"),
    ...require("./namaz.json"),
    ...require("./oruc.json"),
    ...require("./hac.json"),
    ...require("./efendimiz.json"),
    ...require("./bamteli.json"),
    ...require("./hutbeler.json"),
    ...require("./gayb.json"),
    ...require("./prensipler.json"),
    ...require("./soru-cevap.json"),
    ...require("./mektubat.json")
];

const kidContents = [
    ...require("./kids_populer.json"),
    ...require("./kids_rabbim.json"),
    ...require("./kids_kuran.json"),
    ...require("./kids_efendimiz.json"),
    ...require("./kids_peygamberler.json"),
    ...require("./kids_sahabeler.json"),
    ...require("./kids_ilmihal.json"),
    ...require("./kids_ramazan.json"),
    ...require("./kids_kainat_kitabi.json"),
    ...require("./kids_hikayeler.json"),
    ...require("./kids_muzik.json"),
];

let defaultSelection = ''
let all = []
if(window.location.href.includes('eglen')) {
    all = kidContents
    defaultSelection = 'Popüler/Afacanlar'
} else {
    // all = kidContents
    // defaultSelection = 'Popüler/Afacanlar'
  all = adultContents
  defaultSelection = 'Tevhid/Tevhid Delilleri'
}

export {all, defaultSelection}
