import { useState, useEffect } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { Sidebar, Videos } from '../components'
import { all, defaultSelection } from "../utils/content" ;

const Feed = () => {
  const [selectedCategory, setSelectedCategory] = useState(defaultSelection)
  const [videos, setVideos] = useState([])


  useEffect(() => {
    if(window.location.href.includes('eglen')) {
      document.title = "İzle Eğlen"
    } else {
      document.title = "Gözden Kalbe"
    }

    let category = all.filter((category)=> category.name === selectedCategory)[0];
    if(selectedCategory.includes("/")) {
        const route = selectedCategory.split("/");
        const parentCategory = all.filter(
          pcategory => pcategory.name === route[0]
        )[0]
        const childCategory = parentCategory.subgroups?.filter(ccategory => 
          ccategory.name === selectedCategory
        )[0]
        category = childCategory
    } 
    setVideos(category.videos)
    
  }, [selectedCategory])
  
  return (
    <Stack 
      sx = {{
        flexDirection: {sx: 'column', md: 'row'},
        overflow: 'auto', maxHeight:'90vh'
      }}
      >
        <Box sx= {{
          height: {sx:'auto', md: '92vh'},
          borderRight: '1px solid #3d3d3d',
          px: {sx: 0, md: 2}
        }}>
          <Sidebar 
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
          <Typography 
            className='copyright'
            variant="body2" 
            sx={{ mt: 1.5, color: "#fff", }}
          >
            2023
          </Typography>
        </Box>
        <Box p={2} sx={{overflow: 'auto', maxHeight:'90vh', flex: 2 }}>
          <Typography variant='h4' fontWeight='bold' mb={2} 
            sx={{color:'white'}}>
            {selectedCategory}
            <span style={{ color: 'red'}}></span>
          </Typography>
          <Videos videos={videos}/>
        </Box>
    </Stack>
  )
}

export default Feed