import { Stack, Box, Button } from '@mui/material'
import logo  from '../resources/logo.png'
import { Link } from 'react-router-dom'
import SearchBar from './SearchBar'

const Navbar = () => {
  const save = () => {
     localStorage.setItem("name", "a") 
  }
  return (
    <Stack 
      direction={ 'row'} 
      alignItems={ 'center'} 
      p={2} 
      sx={{ position: 'sticky', background:'#000', top:0, justifyContent:'space-between'}}>
        <Link to={'/'} style={{display:'flex', alignItems:'center'}}>
          <img src={logo} alt='logo' height={45} />
        </Link>
        <SearchBar />
    </Stack>
  )
}

export default Navbar